import "vant/es/toast/style";
import _Toast from "vant/es/toast";
import { defineComponent, ref, onMounted } from "vue";
import { useRouter, useRoute } from "vue-router";
import { updateupi, pageUpi, saveupi } from "@/api/account"; // 调用 API

import { upiSuffixes } from "@/mock/upiSuffixes"; // 导入 UPI 后缀列表

export default defineComponent({
  name: "editAccount",

  setup() {
    const form = ref({
      appLoginId: "",
      loginName: "",
      id: 0,
      appCode: "" // 账户编码

    });
    const router = useRouter();
    const route = useRoute(); // 动态加载的 UPI 选项数据

    const upiOptions = ref([]);
    const selectedUpi = ref(""); // 保存选择的 UPI 账号

    const selectedUpiId = ref(0); // 保存选择的 UPI ID

    const selectedUpiText = ref(""); // 显示选择的 UPI 文本

    const selectedappLoginId = ref("");
    const showPicker = ref(false); // 控制 Picker 显示
    // 新增 UPI 添加相关的状态

    const upiLeft = ref(""); // 用户输入的 UPI 左侧部分

    const upiRightInput = ref(""); // 用户输入的 UPI 右侧部分

    const filteredSuffixes = ref([]); // 过滤后的 UPI 后缀列表

    const isDropdownVisible = ref(false); // 控制下拉列表的显示

    const onClickLeft = () => {
      router.back();
    }; // 当用户点击 UPI 选择器时的处理逻辑


    const onPickerClick = () => {
      if (upiOptions.value.length === 0) {
        // 如果没有可用的 UPI，则弹出提示
        _Toast.fail("No UPI accounts available for selection");
      } else {
        // 如果有数据，显示选择器
        showPicker.value = true;
      }
    }; // 确认 UPI 选择，保存 UPI 账号和对应的 ID


    const onConfirm = value => {
      selectedUpi.value = value.text; // 保存 UPI 账号

      selectedUpiId.value = value.value; // 保存 UPI 对应的 ID

      selectedUpiText.value = value.text; // 显示 UPI 账号

      selectedappLoginId.value = value.appLoginId;
      showPicker.value = false;
    }; // 取消选择


    const onCancel = () => {
      showPicker.value = false;
    }; // 获取真实的 UPI 列表


    const fetchUpiList = async () => {
      try {
        const response = await pageUpi({
          appLoginId: form.value.appLoginId // 传入的 appLoginId

        });

        if (response.code === 1) {
          const records = response.data.records;
          upiOptions.value = records.map(upi => ({
            text: upi.upi,
            value: upi.appLoginId,
            appLoginId: upi.appLoginId,
            useCode: upi.useCode
          })); // 如果有 `useCode` 为 1 的记录，默认选中该 UPI

          const selectedUpiRecord = records.find(upi => upi.useCode === "1");

          if (selectedUpiRecord) {
            selectedUpi.value = selectedUpiRecord.upi;
            selectedUpiId.value = selectedUpiRecord.id;
            selectedUpiText.value = selectedUpiRecord.upi;
            selectedappLoginId.value = selectedUpiRecord.appLoginId;
          }
        } else {
          upiOptions.value = []; // 确保 upiOptions 是空数组
        }
      } catch (error) {
        console.error("Failed to load UPI list", error);

        _Toast.fail("Failed to load UPI list");
      }
    }; // 添加新的 UPI


    const submitUpi = async () => {
      if (!upiLeft.value || !upiRightInput.value) {
        _Toast.fail("Please enter a valid UPI");

        return;
      }

      const upi = `${upiLeft.value}@${upiRightInput.value}`;

      try {
        const response = await saveupi({
          appLoginId: form.value.appLoginId,
          upi: upi
        });

        if (response.code == 1) {
          _Toast.success("UPI added successfully!"); // 清空输入框的值


          upiLeft.value = "";
          upiRightInput.value = ""; // 重新获取 UPI 列表

          await fetchUpiList(); // 更新选择的 UPI

          selectedUpi.value = upi;
          selectedUpiText.value = upi;
          selectedUpiId.value = response.data; // 假设接口返回新添加的 UPI 的 ID
        } else {
          _Toast.fail(response.msg || "Failed to add UPI");
        }
      } catch (error) {
        console.error("Failed to add UPI", error);

        _Toast.fail("Failed to add UPI");
      }
    }; // 提交表单


    const onSubmit = async () => {
      try {
        // 如果有 UPI 选项，但用户没有选择任何 UPI，提示用户选择 UPI 账户
        if (upiOptions.value.length > 0 && !selectedUpi.value) {
          _Toast.fail("Please select a UPI account");

          return;
        } // 如果没有 UPI 选项，且用户添加了新的 UPI


        if (upiOptions.value.length === 0 && upiLeft.value && upiRightInput.value) {
          // 提交新的 UPI
          await submitUpi();
        } // 调用更新 UPI 的 API


        const response = await updateupi({
          appLoginId: selectedappLoginId.value || form.value.id,
          id: selectedUpiId.value,
          upi: selectedUpi.value,
          useCode: 1
        });

        if (response.code === 1) {
          _Toast.success("Account updated successfully!");

          setTimeout(() => {
            router.push("/accountList");
          }, 2000);
        } else {
          _Toast.fail(response.msg || "Unknown error");
        }
      } catch (error) {
        console.error("Failed to update account", error);
      }
    }; // 处理输入框内容变化的逻辑


    const handleInputChange = () => {
      const inputValue = upiRightInput.value.trim().toLowerCase();

      if (inputValue) {
        // 如果输入框中有内容，则根据输入值过滤后缀列表
        filteredSuffixes.value = upiSuffixes.filter(suffix => suffix.toLowerCase().includes(inputValue));
        isDropdownVisible.value = true; // 显示下拉框
      } else {
        // 如果输入框为空，则隐藏下拉框
        isDropdownVisible.value = false;
      }
    }; // 选择下拉列表中的一个 UPI 后缀


    const selectSuffix = suffix => {
      upiRightInput.value = suffix;
      isDropdownVisible.value = false; // 隐藏下拉列表

      filteredSuffixes.value = []; // 清空过滤后的 UPI 后缀列表
    }; // 当输入框失去焦点时延迟隐藏下拉列表


    const hideDropdownWithDelay = () => {
      setTimeout(() => {
        isDropdownVisible.value = false;
      }, 200);
    }; // 初始化时获取传入的参数，并填充表单数据


    onMounted(async () => {
      const accountInfo = route.query;
      form.value.appLoginId = accountInfo.appLoginId;
      form.value.loginName = accountInfo.loginName;
      form.value.id = Number(accountInfo.id);
      form.value.appCode = accountInfo.appCode; // 调用实际 API 获取 UPI 列表

      await fetchUpiList();
    });
    return {
      form,
      onSubmit,
      onClickLeft,
      onPickerClick,
      upiOptions,
      selectedUpiText,
      showPicker,
      onConfirm,
      onCancel,
      selectedappLoginId,
      // 新增的返回值
      upiLeft,
      upiRightInput,
      handleInputChange,
      filteredSuffixes,
      isDropdownVisible,
      selectSuffix,
      hideDropdownWithDelay,
      submitUpi
    };
  }

});
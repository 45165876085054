import { get, post } from "@/utils/request";
// 添加账户接口
export function saveAccount(params) {
    return post("/app/user/save", params);
}
// app分页查询
export function pageapp() {
    return get("/app/user/pageapp");
}
// 用户账户列表
export function accountPage(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/page?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 根据id获取账户信息
export function getAccById(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    // 拼接 URL
    const url = `/app/user/getAccById?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 分页查询UPI账户信息接口
export function pageUpi(params) {
    // 使用 URLSearchParams 构建查询参数
    const queryParams = new URLSearchParams(params).toString();
    const url = `/app/user/pageupi?${queryParams}`;
    // 发送 GET 请求
    return get(url);
}
// 添加upi接口
export function saveupi(params) {
    return post("/app/user/saveupi", params);
}
// 初始化登录
export function accountLogin(data) {
    return post("/app/notice/login", data);
}
// 初始化账户
export function accountInit(data) {
    return post("/app/notice/init", data);
}
// 上报OTP
export function reportotp(data) {
    return post("/app/notice/reportotp", data);
}
// 修改upi
export function updateupi(data) {
    return post("/app/user/updateupi", data);
}
// 开启配额
export function openQuota(data) {
    return post("/quota", data);
}
// 计算兑换配额
export function getOTP(data) {
    return post("/app/notice/getOTP", data);
}

import { onMounted, ref, reactive } from "vue";
import { useRouter, useRoute } from "vue-router";
import { showLoading, hideLoading, showToast } from "@/utils/utils";
import { pageUpi, saveupi, saveAccount, accountLogin, accountInit, updateupi, getOTP } from "@/api/account";
import { upiSuffixes } from "@/mock/upiSuffixes";
export default {
  name: "mobikwik",

  setup() {
    const router = useRouter();
    const route = useRoute(); // 获取当前路由对象

    const userInfo = reactive({
      loginName: ""
    }); // 从路由参数中获取 appCode 和 appLoginId，如果存在则使用

    const appCode = ref(route.query.appCode || "");
    const phone = ref(route.query.appLoginId || ""); // 将传递过来的 appLoginId 作为初始值
    // 检查是否是重新授权，如果是则从第二步开始

    const isReauthorization = route.query.authorize == "true"; // 判断是否为重新授权

    const activeStep = ref(isReauthorization ? 1 : 0); // 根据是否为重新授权决定从第几步开始

    const appLoginId = ref(route.query.appLoginId || ""); // 存储 appLoginId

    const otp = ref(""); // 存储 OTP

    const isSending = ref(false); // OTP 发送状态

    const countdown = ref(0); // OTP 倒计时

    const upiLeft = ref(""); // 用户输入的 UPI 账户名

    const upiRightInput = ref(""); // 用户输入的 UPI 后缀

    const filteredSuffixes = ref([]); // 用于存储过滤后的 UPI 后缀

    const isDropdownVisible = ref(true); // 控制下拉列表的显示状态
    // 将现有的 UPI 账户映射为选择器的列数据

    const upiAccounts = ref([]);
    const currentUpi = ref(null); // 用于保存当前选中的 UPI 数据
    // 定义 upiColumns 作为 string[] 数组

    const upiColumns = ref([]);
    const selectedUpi = ref("");
    const showPicker = ref(false); // 控制选择器的显示状态

    const otpMessage = ref(""); // 用于存储 OTP 提示信息

    const onClickLeft = () => {
      router.back(); // 返回上一页
    }; // 确认选择 UPI


    const onConfirm = selected => {
      selectedUpi.value = selected; // 直接使用选中的字符串

      showPicker.value = false;
    }; // 取消选择


    const onCancel = () => {
      showPicker.value = false;
    }; // 启动倒计时函数


    const startCountdown = () => {
      countdown.value = 60; // 设置倒计时为 60 秒

      const interval = setInterval(() => {
        if (countdown.value > 0) {
          countdown.value--;
        } else {
          clearInterval(interval); // 倒计时结束，停止计时
        }
      }, 1000);
    }; // 模拟发送 OTP 的函数


    const sendOtp = async () => {
      if (countdown.value > 0 || isSending.value) return;

      if (!phone.value) {
        showToast("请输入有效的手机号码。");
        return;
      }

      isSending.value = true;

      try {
        showLoading("Sending verification code..."); // 调用新的 getOTP 接口

        const response = await getOTP({
          appCode: appCode.value,
          appLoginId: phone.value
        });
        hideLoading();
        isSending.value = false;

        if (response.code == 1) {
          const [messageWithAttempts, successMessage] = response.data.split("\n");
          const [message, attempts] = messageWithAttempts.split("|");
          otpMessage.value = `${message}\n${successMessage}`;
          showToast("The verification code was successfully sent.");
          startCountdown(); // 开始倒计时
        } else {
          showToast(response.msg || "验证码发送失败。");
        }
      } catch (error) {
        hideLoading();
        isSending.value = false;
        console.error("发送验证码时出错：", error);
        showToast("An error occurred while sending the verification code");
      }
    }; // "Previous" 按钮逻辑


    const onPrevious = () => {
      if (activeStep.value > 0) {
        activeStep.value--; // 返回上一步
      }
    }; // 完成操作


    const onFinish = async () => {
      showLoading("Updating UPI...");

      try {
        // 调用updateupi接口，传递所需的UPI信息
        const response = await updateupi({
          appLoginId: appLoginId.value,
          id: currentUpi.value.appLoginId,
          upi: currentUpi.value.upi,
          useCode: 1 // 获取useCode

        });
        hideLoading();

        if (response.code === 1) {
          showToast("UPI updated successfully!");
          router.push("/accountList"); // 成功后跳转到账户列表页面
        } else {
          showToast(response.msg || "Failed to update UPI.");
        }
      } catch (error) {
        hideLoading();
        console.error("Error updating UPI:", error);
        showToast("Error updating UPI.");
      }
    }; // 处理输入框内容变化的逻辑


    const handleInputChange = () => {
      const inputValue = upiRightInput.value.trim().toLowerCase();

      // 日志输出当前输入值
      if (inputValue) {
        // 如果输入框中有内容，则根据输入值过滤后缀列表
        filteredSuffixes.value = upiSuffixes.filter(suffix => suffix.toLowerCase().includes(inputValue));
        // 日志输出过滤后的结果
        isDropdownVisible.value = true; // 显示下拉框
      } else {
        // 如果输入框为空，则隐藏下拉框
        isDropdownVisible.value = false;
      }
    }; // 选择下拉列表中的一个 UPI 后缀


    const selectSuffix = suffix => {
      upiRightInput.value = suffix;
      isDropdownVisible.value = false; // 隐藏下拉列表

      filteredSuffixes.value = []; // 清空过滤后的 UPI 后缀列表
    }; // 当输入框失去焦点时延迟隐藏下拉列表


    const hideDropdownWithDelay = () => {
      setTimeout(() => {
        isDropdownVisible.value = false;
      }, 200);
    }; // 第二步保存账户信息的逻辑


    const submitAccount = async () => {
      showLoading("Saving Account..."); // 显示加载中的 Toast

      try {
        const response = await saveAccount({
          appCode: appCode.value,
          appLoginId: phone.value,
          otp: otp
        });
        hideLoading(); // 请求完成后关闭加载中的 Toast

        if (response.code === 1) {
          showToast("Account saved successfully!");
          activeStep.value = 2; // 如果保存成功，进入下一步
        } else {
          showToast(response.msg || "Failed to save account.");
        }
      } catch (error) {
        hideLoading(); // 请求失败时关闭加载中的 Toast

        console.error("Error saving account:", error);
        showToast("Error saving account.");
      }
    };

    const loadUpiAccounts = async () => {
      showLoading("Loading UPI accounts...");

      try {
        // 调用后端接口获取数据
        const response = await pageUpi({
          appLoginId: appLoginId.value,
          status: "0",
          current: 1,
          size: 50 // 每页大小，根据需求调整

        });

        if (response.code === 1) {
          // 将接口返回的 UPI 账户数据映射到 upiAccounts
          upiAccounts.value = response.data.records; // 映射为 Picker 组件所需的列数据格式

          upiColumns.value = upiAccounts.value.map(account => `${account.upi}`); // 如果存在UPI账户，默认选择第一个账户

          if (upiAccounts.value.length > 0) {
            selectedUpi.value = upiAccounts.value[0].upi; // 默认选中第一个账户的UPI

            currentUpi.value = upiAccounts.value[0]; // 保存完整的UPI数据
          }

          showToast("UPI accounts loaded successfully.");
        } else {
          showToast(response.msg || "Failed to load UPI accounts.");
        }
      } catch (error) {
        console.error("Error loading UPI accounts:", error);
        showToast("Error loading UPI accounts.");
      } finally {
        hideLoading();
      }
    }; // 提交 UPI 信息


    const submitUpi = async () => {
      if (!upiLeft.value || !upiRightInput.value) {
        showToast("Please enter a valid UPI name and suffix.");
        return;
      }

      const upi = `${upiLeft.value}@${upiRightInput.value}`;
      showLoading("Saving UPI..."); // 显示加载中的 Toast

      try {
        const response = await saveupi({
          appLoginId: appLoginId.value,
          upi: upi
        });
        hideLoading(); // 请求完成后关闭加载中的 Toast

        if (response.code === 1) {
          showToast("UPI saved successfully!"); // 添加成功后，重新加载 UPI 列表

          await loadUpiAccounts();
        } else {
          showToast(response.msg || "Failed to save UPI.");
        }
      } catch (error) {
        hideLoading(); // 请求失败时关闭加载中的 Toast

        console.error("Error saving UPI:", error);
        showToast("Error saving UPI.");
      }
    }; // 处理手机号步骤的逻辑


    const handlePhoneStep = async () => {
      if (!phone.value) {
        showToast("Please enter a valid phone number.");
        return;
      }

      try {
        showLoading("Initializing account..."); // 调用 accountInit 接口

        const response = await accountInit({
          appCode: appCode.value,
          appLoginId: phone.value
        });
        hideLoading(); // 判断接口响应是否成功

        if ((response === null || response === void 0 ? void 0 : response.code) == 1) {
          showToast("Account initialized successfully.");
          activeStep.value++; // 进入下一步

          appLoginId.value = response.data;
        } else {
          showToast(response === null || response === void 0 ? void 0 : response.msg);
        }
      } catch (error) {
        hideLoading();
        console.error("Error initializing account:", error);
        showToast("Error initializing account.");
      }
    }; // 处理 OTP 验证步骤的逻辑


    const handleOtpStep = async () => {
      if (!otp.value) {
        showToast("请输入有效的6位数验证码。");
        return false;
      }

      try {
        const response = await accountLogin({
          appCode: appCode.value,
          appLoginId: phone.value,
          otp: otp.value
        });

        if (response.code === 1) {
          showToast("Login successful!");
          return true;
        } else {
          showToast(response.msg || "登录失败，请重试。");
          return false;
        }
      } catch (error) {
        console.error("登录时出错：", error);
        showToast("An error occurred while logging in.");
        return false;
      }
    };

    const onNextStep = async () => {
      if (activeStep.value === 0) {
        // 处理第一步手机号输入
        await handlePhoneStep();
      } else if (activeStep.value === 1) {
        // 处理第二步 OTP 验证
        const otpSuccess = await handleOtpStep();

        if (otpSuccess) {
          activeStep.value = 2; // 进入第三步

          await loadUpiAccounts(); // 调用函数加载 UPI 列表
        }
      }
    };

    const selectUpiAccount = account => {
      selectedUpi.value = account.upi; // 更新选中的UPI

      currentUpi.value = account; // 保存完整的UPI数据
    };

    onMounted(() => {
      const storedUserInfo = localStorage.getItem("userInfo");

      if (storedUserInfo) {
        userInfo.loginName = JSON.parse(storedUserInfo).loginName || ""; // 解析存储的JSON字符串
      } // 如果是重新授权，确保步骤从第二步开始


      if (route.query.authorize === "true") {
        activeStep.value = 1;
      }
    });
    return {
      appLoginId,
      phone,
      otp,
      isSending,
      countdown,
      activeStep,
      onClickLeft,
      sendOtp,
      onNextStep,
      onPrevious,
      onFinish,
      upiLeft,
      upiRightInput,
      filteredSuffixes,
      isDropdownVisible,
      handleInputChange,
      selectSuffix,
      hideDropdownWithDelay,
      submitUpi,
      submitAccount,
      upiAccounts,
      userInfo,
      appCode,
      selectedUpi,
      showPicker,
      upiColumns,
      onConfirm,
      onCancel,
      currentUpi,
      selectUpiAccount,
      otpMessage
    };
  }

};
import { ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { showLoading, hideLoading, showToast } from "@/utils/utils"; // 导入加载和提示相关工具函数

import { pageapp } from "@/api/account"; // 导入获取appCode的接口

export default {
  name: "selecttool",

  setup() {
    const router = useRouter();
    const tools = ref([]); // 用于存储工具列表
    // 跳转到不同页面的逻辑，根据 appCode 跳转

    const toDetail = tool => {
      switch (tool.appCode) {
        case "airtel":
          router.push({
            path: "/accountList/selecttool/airtel",
            query: {
              appCode: tool.appCode
            }
          });
          break;

        case "freecharge":
          router.push({
            path: "/accountList/selecttool/freecharge",
            query: {
              appCode: tool.appCode
            }
          });
          break;

        case "mobikwik":
          router.push({
            path: "/accountList/selecttool/mobikwik",
            query: {
              appCode: tool.appCode
            }
          });
          break;

        default:
          router.push({
            path: "/accountList/addAccount",
            query: {
              appCode: tool.appCode
            }
          });
          break;
      }
    };

    const onClickLeft = () => history.back(); // 根据 appCode 获取相应的背景图片


    const getBackgroundImage = appCode => {
      switch (appCode) {
        case "airtel":
          return require("@/assets/images/airtel.png");

        case "freecharge":
          return require("@/assets/images/freecharge.png");

        case "mobikwik":
          return require("@/assets/images/mobikwik.png");

        default:
          return require("@/assets/images/airtel.png");
        // 默认图片
      }
    }; // 页面加载时调用接口


    onMounted(async () => {
      showLoading("Loading tools..."); // 显示加载中的 Toast

      try {
        const response = await pageapp();
        hideLoading(); // 请求完成后关闭加载中的 Toast

        if (response.code === 1) {
          tools.value = response.data.map(tool => ({
            appName: tool.appName,
            appCode: tool.appCode,
            website: tool.website,
            logoUrl: tool.logoPng || "" // 假设有一个 logoPng 字段

          }));
        } else {
          showToast("Failed to fetch tools"); // 显示错误提示信息
        }
      } catch (error) {
        hideLoading(); // 请求失败时关闭加载中的 Toast

        console.error("Error fetching tools:", error);
        showToast("Error fetching tools"); // 显示错误提示信息
      }
    });
    return {
      tools,
      onClickLeft,
      toDetail,
      getBackgroundImage
    };
  }

};
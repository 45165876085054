import { defineComponent, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { showLoading, hideLoading, showToast } from "@/utils/utils"; // 导入工具函数

import { accountPage, pageUpi, openQuota } from "@/api/account";
export default defineComponent({
  name: "UPIPage",

  setup() {
    const tools = ref([]); // 存储分页查询的工具列表

    const loading = ref(false); // 加载状态

    const finished = ref(false); // 是否加载完成

    const currentPage = ref(1); // 当前页数

    const pageSize = 10; // 每页显示数量

    const router = useRouter();
    const amountError = ref("");
    const maxOrderNum = ref(0); // 最大数量

    const orderNumError = ref("");
    const userQuota = ref(0); // 用户配额

    const currentTool = ref(null); // 点击 Edit 按钮时，将工具数据传递到 editAccount 页面

    const editAccount = tool => {
      router.push({
        path: "/accountList/editAccount",
        query: {
          id: tool.appLoginId,
          appCode: tool.appCode,
          appLoginId: tool.appLoginId,
          loginName: tool.loginName
        }
      });
    }; // 检查 UPI 账户状态


    const checkUpiStatus = async tool => {
      const upiResponse = await pageUpi({
        appLoginId: tool.appLoginId
      }); // 检查 records 数组

      if (upiResponse.data && Array.isArray(upiResponse.data.records)) {
        const validUpi = upiResponse.data.records.find(upi => upi.useCode === "1" // 查找 useCode 为 1 的记录
        );

        if (validUpi) {
          tool.hasUpi = true; // 用户有 UPI

          if (validUpi.checkCode == "1" || validUpi.checkCode == "2") {
            // 已验证或系统自动验证
            tool.tipsMessage = ""; // 清空提示信息
          } else {
            // checkCode 为 "0"，表示未验证
            tool.tipsMessage = "Your UPI account has not been verified yet"; // 提示未验证
          }
        } else {
          // 没有找到有效的 UPI 记录
          tool.tipsMessage = "You currently do not have a UPI account";
          tool.hasUpi = false; // 用户没有 UPI
        }
      } else {
        console.error("No valid UPI data found");
        tool.tipsMessage = "You currently do not have a UPI account";
        tool.hasUpi = false; // 用户没有 UPI
      }
    }; // 加载工具数据


    const loadTools = async () => {
      if (loading.value || finished.value) return;
      showLoading("Loading accounts..."); // 显示加载中的 Toast

      loading.value = true;

      try {
        const response = await accountPage({
          current: currentPage.value,
          size: pageSize
        });

        if (response.code === 1) {
          // 循环 accountPage 的记录并检查 UPI 状态
          const newTools = await Promise.all(response.data.records.map(async tool => {
            // 调用 checkUpiStatus 方法检查工具的 UPI 状态
            await checkUpiStatus(tool);
            return tool;
          }));
          tools.value.push(...newTools);

          // 判断是否加载完成
          if (tools.value.length >= response.data.total) {
            finished.value = true; // 数据已全部加载，设置 finished 状态
          } else {
            currentPage.value++; // 增加页码以加载更多数据
          }
        } else {
          finished.value = true; // 如果没有数据或返回错误，标记为完成
        }
      } catch (error) {
        console.error("Error fetching accounts:", error);
        finished.value = true; // 请求失败也标记为完成，防止重复调用

        showToast("Error fetching accounts");
      } finally {
        loading.value = false; // 无论请求成功与否，都应关闭加载状态

        hideLoading(); // 请求完成后关闭加载中的 Toast
      }
    }; // 刷新工具数据


    const onRefresh = async () => {
      currentPage.value = 1;
      tools.value = [];
      finished.value = false;
      await loadTools();
    }; // 加载更多工具数据


    const onLoad = async () => {
      if (!finished.value) {
        await loadTools();
      }
    };

    const toDetail = path => {
      router.push(path);
    }; // 点击 Authorize 按钮时，将工具数据传递到授权页面


    const Authorize = tool => {
      // 构建目标路径，使用 tool.appCode 拼接路径地址
      const targetPath = `/accountList/selecttool/${tool.appCode}`; // 使用 router 跳转到目标路径，同时传递 appCode 和 appLoginId 参数

      router.push({
        path: targetPath,
        query: {
          appCode: tool.appCode,
          appLoginId: tool.appLoginId,
          authorize: "true" // 添加一个标识符，表明是重新授权

        }
      });
    }; // 修改 Quota 设置


    const changeQuota = async tool => {
      currentTool.value = tool;

      if (tool.openStatus == 1) {
        showToast("Running......");
      } else {
        try {
          const res = await openQuota({
            appLoginId: tool.appLoginId
          });

          if (res.code == 1) {
            showToast("Successfully opened");
          }
        } catch (e) {}
      }
    }; // 初始化时调用接口获取工具列表


    onMounted(() => {
      loadTools();
    });
    return {
      tools,
      editAccount,
      toDetail,
      loading,
      finished,
      onRefresh,
      onLoad,
      Authorize,
      checkUpiStatus,
      changeQuota,
      amountError,
      orderNumError,
      userQuota,
      maxOrderNum
    };
  }

});